/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useCallback, useEffect } from 'react';
import { GenericDataType, styled, t } from '@superset-ui/core';
import { Form, FormItem, FormProps } from 'src/components/Form';
import Select from 'src/components/Select/Select';
import { AntdCheckbox, AntdInput, Col, Row } from 'src/components';
import { InputNumber } from 'src/components/Input';
import Button from 'src/components/Button';
// @ts-ignore
import { ChromePicker } from 'react-color';
import {
  COMPARATOR,
  ConditionalFormattingConfig,
  MULTIPLE_VALUE_COMPARATORS,
} from '@superset-ui/chart-controls';
import { useForm } from 'antd/es/form/Form';
import { theme } from '../../../../preamble';
import { LabelCss } from '../../../../components/Form/FormLabel';
import { useForceUpdate } from '../../../../components/Form/Form';
import { numberOperatorOptions, textOperatorOptions } from './types';

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const ChromePickerWrapper = (props: any) => (
  <ChromePicker
    {...props}
    onChangeComplete={(value: any) => {
      // eslint-disable-next-line no-debugger
      // debugger;
      props.onChangeComplete(
        value?.rgb
          ? `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${value.rgb.a})`
          : null,
      );
    }}
  />
);

const ColorPicker = styled(ChromePickerWrapper)`
  box-shadow: ${theme.colors.grayscale.light2} 0px 0px 0px 1px !important;
`;

const JustifyEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const targetValueValidator =
  (
    compare: (targetValue: number, compareValue: number) => boolean,
    rejectMessage: string,
  ) =>
  (targetValue: number | string) =>
  (_: any, compareValue: number | string) => {
    if (
      !targetValue ||
      !compareValue ||
      compare(Number(targetValue), Number(compareValue))
    ) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(rejectMessage));
  };

const targetValueLeftValidator = targetValueValidator(
  (target: number, val: number) => target > val,
  t('This value should be smaller than the right target value'),
);

const targetValueRightValidator = targetValueValidator(
  (target: number, val: number) => target < val,
  t('This value should be greater than the left target value'),
);

const isOperatorMultiValue = (operator?: COMPARATOR) =>
  operator && MULTIPLE_VALUE_COMPARATORS.includes(operator);

const isOperatorNone = (operator?: COMPARATOR) =>
  !operator || operator === COMPARATOR.NONE;

const rulesRequired = [{ required: true, message: t('Required') }];

type GetFieldValue = Pick<Required<FormProps>['form'], 'getFieldValue'>;
const rulesTargetValueLeft = [
  { required: true, message: t('Required') },
  ({ getFieldValue }: GetFieldValue) => ({
    validator: targetValueLeftValidator(getFieldValue('targetValueRight')),
  }),
];

const rulesTargetValueRight = [
  { required: true, message: t('Required') },
  ({ getFieldValue }: GetFieldValue) => ({
    validator: targetValueRightValidator(getFieldValue('targetValueLeft')),
  }),
];

const targetValueLeftDeps = ['targetValueRight'];
const targetValueRightDeps = ['targetValueLeft'];

const operatorField = (operatorOptions: any) => (
  <FormItem
    name="operator"
    label={t('Operator')}
    rules={rulesRequired}
    initialValue={operatorOptions[0].value}
  >
    <Select ariaLabel={t('Operator')} options={operatorOptions} />
  </FormItem>
);

const renderNumberOperatorFields = ({ getFieldValue }: GetFieldValue) => {
  const operator = getFieldValue('operator');

  switch (true) {
    case isOperatorNone(operator):
      return (
        <Row gutter={12}>
          <Col span={6}>{operatorField(numberOperatorOptions)}</Col>
        </Row>
      );
    case isOperatorMultiValue(operator):
      // @ts-ignore
      return (
        <>
          <Row gutter={12}>
            <Col span={9}>
              <FormItem
                name="targetValueLeft"
                label={t('Left value')}
                rules={rulesTargetValueLeft}
                dependencies={targetValueLeftDeps}
                validateTrigger="onBlur"
                trigger="onBlur"
              >
                <FullWidthInputNumber />
              </FormItem>
            </Col>
            <Col span={6}>{operatorField(numberOperatorOptions)}</Col>
            <Col span={9}>
              <FormItem
                name="targetValueRight"
                label={t('Right value')}
                rules={rulesTargetValueRight}
                dependencies={targetValueRightDeps}
                validateTrigger="onBlur"
                trigger="onBlur"
              >
                <FullWidthInputNumber />
              </FormItem>
            </Col>
          </Row>
        </>
      );
    default:
      return (
        <Row gutter={12}>
          <Col span={6}>{operatorField(numberOperatorOptions)}</Col>
          <Col span={18}>
            <FormItem
              name="targetValue"
              label={t('Target value')}
              rules={rulesRequired}
            >
              <FullWidthInputNumber />
            </FormItem>
          </Col>
        </Row>
      );
  }
};

const renderTextOperatorFields = ({ getFieldValue }: GetFieldValue) => {
  const operator = getFieldValue('operator');

  switch (true) {
    case isOperatorNone(operator):
      return (
        <Row gutter={12}>
          <Col span={6}>{operatorField(textOperatorOptions)}</Col>
        </Row>
      );
    default:
      return (
        <Row gutter={12}>
          <Col span={6}>{operatorField(textOperatorOptions)}</Col>
          <Col span={18}>
            <FormItem
              name="targetValue"
              label={t('Target value')}
              rules={rulesRequired}
            >
              <AntdInput type="text" />
            </FormItem>
          </Col>
        </Row>
      );
  }
};

export const FormattingPopoverContent = ({
  config,
  onChange,
  columns = [],
}: {
  config?: ConditionalFormattingConfig;
  onChange: (config: ConditionalFormattingConfig) => void;
  columns: { label: string; value: string; coltype: GenericDataType }[];
}) => {
  const [form] = useForm();
  const forceUpdate = useForceUpdate();
  const getColtype = useCallback(
    () => columns.find(c => form.getFieldValue('column') === c.value)?.coltype,
    [form, columns],
  );
  const onColumnChange = (reason?: string) => {
    if (getColtype() === GenericDataType.STRING) {
      form.setFieldsValue({ isGradient: false });
    }
    form.setFieldsValue({ coltype: getColtype() });
    if (reason !== 'init') {
      form.setFieldsValue({ operator: COMPARATOR.NONE });
    }
    forceUpdate();
  };

  useEffect(() => onColumnChange('init'), []);

  return (
    <Form
      form={form}
      onFinish={onChange}
      initialValues={config}
      requiredMark={false}
      layout="vertical"
    >
      <FormItem
        name="column"
        label={t('Column')}
        rules={rulesRequired}
        initialValue={columns[0]?.value}
      >
        <Select
          ariaLabel={t('Select column')}
          options={columns}
          onChange={() => onColumnChange()}
        />
      </FormItem>
      <FormItem name="coltype" hidden />
      <FormItem noStyle shouldUpdate>
        {() =>
          (getColtype() === GenericDataType.NUMERIC &&
            renderNumberOperatorFields(form)) ||
          (getColtype() === GenericDataType.STRING &&
            renderTextOperatorFields(form))
        }
      </FormItem>
      <Row>
        <FormItem name="applyToText" valuePropName="checked">
          <AntdCheckbox>
            <span css={LabelCss}>{t('Apply to text')}</span>
          </AntdCheckbox>
        </FormItem>
      </Row>
      <Row>
        <FormItem name="applyToWholeRow" valuePropName="checked">
          <AntdCheckbox>
            <span css={LabelCss}>{t('Apply to whole row')}</span>
          </AntdCheckbox>
        </FormItem>
      </Row>
      {getColtype() !== GenericDataType.STRING && (
        <Row>
          <FormItem name="isGradient" valuePropName="checked">
            <AntdCheckbox>
              <span css={LabelCss}>{t('Gradient')}</span>
            </AntdCheckbox>
          </FormItem>
        </Row>
      )}
      <FormItem noStyle shouldUpdate>
        {() =>
          getColtype() !== GenericDataType.STRING &&
          form.getFieldValue('isGradient') ? (
            <Row gutter={12}>
              <Col span={12}>
                <FormItem
                  name="minColor"
                  initialValue="#3991f8"
                  valuePropName="color"
                  trigger="onChangeComplete"
                  label={t('Minimum value color')}
                >
                  <ColorPicker />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  name="maxColor"
                  initialValue="#3991f8"
                  valuePropName="color"
                  trigger="onChangeComplete"
                  label={t('Maximum value color')}
                >
                  <ColorPicker />
                </FormItem>
              </Col>
            </Row>
          ) : (
            <Row gutter={12}>
              <Col span={12}>
                <FormItem
                  name="colorScheme"
                  initialValue="#3991f8"
                  valuePropName="color"
                  trigger="onChangeComplete"
                >
                  <ColorPicker />
                </FormItem>
              </Col>
            </Row>
          )
        }
      </FormItem>
      <FormItem>
        <JustifyEnd>
          <Button htmlType="submit" buttonStyle="primary">
            {t('Apply')}
          </Button>
        </JustifyEnd>
      </FormItem>
    </Form>
  );
};
