/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import { ReactNode } from 'react';
import { PopoverProps } from 'antd/lib/popover';
import {
  COMPARATOR,
  ConditionalFormattingConfig,
  ControlComponentProps,
} from '@superset-ui/chart-controls';
import { GenericDataType, t } from '@superset-ui/core';

export type ConditionalFormattingControlProps = ControlComponentProps<
  ConditionalFormattingConfig[]
> & {
  columnOptions: { label: string; value: string; coltype: GenericDataType }[];
  removeIrrelevantConditions: boolean;
  verboseMap: Record<string, string>;
  label: string;
  description: string;
};

export type FormattingPopoverProps = PopoverProps & {
  columns: { label: string; value: string; coltype: GenericDataType }[];
  onChange: (value: ConditionalFormattingConfig) => void;
  config?: ConditionalFormattingConfig;
  title: string;
  children: ReactNode;
};

export const numberOperatorOptions = [
  { value: COMPARATOR.NONE, label: t('None') },
  { value: COMPARATOR.GREATER_THAN, label: '>' },
  { value: COMPARATOR.LESS_THAN, label: '<' },
  { value: COMPARATOR.GREATER_OR_EQUAL, label: '≥' },
  { value: COMPARATOR.LESS_OR_EQUAL, label: '≤' },
  { value: COMPARATOR.EQUAL, label: '=' },
  { value: COMPARATOR.NOT_EQUAL, label: '≠' },
  { value: COMPARATOR.BETWEEN, label: '< x <' },
  { value: COMPARATOR.BETWEEN_OR_EQUAL, label: '≤ x ≤' },
  { value: COMPARATOR.BETWEEN_OR_LEFT_EQUAL, label: '≤ x <' },
  { value: COMPARATOR.BETWEEN_OR_RIGHT_EQUAL, label: '< x ≤' },
];

export const textOperatorOptions = [
  { value: COMPARATOR.NONE, label: t('None') },
  { value: COMPARATOR.EQUAL, label: '=' },
  { value: COMPARATOR.CONTAINS, label: t('contains') },
  { value: COMPARATOR.NOT_CONTAINS, label: t('not contains') },
  { value: COMPARATOR.BEGINS_WITH, label: t('begins with') },
  { value: COMPARATOR.ENDS_WITH, label: t('ends with') },
];

export const allOperatorOptions = [
  ...numberOperatorOptions,
  ...textOperatorOptions,
];
