import React, { useEffect, useState } from 'react';
import { styled, css, t, useTheme } from '@superset-ui/core';
import Icons from 'src/components/Icons';
import ControlHeader from 'src/explore/components/ControlHeader';
import { CustomControlConfig } from '@superset-ui/chart-controls';
import {
  AddControlLabel,
  CaretContainer,
  OptionControlContainer,
} from '../OptionControls';
import ControlPopover from '../ControlPopover/ControlPopover';

const Style = styled.div`
  ${({ theme }) => css`
    padding: ${theme.gridUnit}px;
    border: solid 1px ${theme.colors.grayscale.light2};
    border-radius: ${theme.gridUnit}px;
  `}
`;

export const StyledOptionControlContainer = styled(OptionControlContainer)`
  &,
  & > div {
    margin-bottom: ${({ theme }) => theme.gridUnit}px;
    :last-child {
      margin-bottom: 0;
    }
  }

  .collection-item {
    width: calc(100% - 24px);
  }
`;

export const CloseButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.light1};
    height: 100%;
    width: ${theme.gridUnit * 6}px;
    border: none;
    border-right: solid 1px ${theme.colors.grayscale.dark2}0C;
    padding: 0;
    outline: none;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  `}
`;

interface Props<C> {
  value: C[];
  onChange: (value: C[]) => void;
  popoverContentRenderer: (
    item: C | null,
    onChange: (newItem: C) => void,
  ) => React.ReactNode;
  itemRenderer: (item: C) => React.ReactNode;
}

export default function CollectionControl2<C>({
  value,
  onChange,
  popoverContentRenderer,
  itemRenderer,
  ...props
}: Omit<CustomControlConfig<Props<C>>, 'warning'>) {
  const theme = useTheme();
  const [items, setItems] = useState<C[]>(value ?? []);
  const [popoverVisible, setPopoverVisible] = useState<boolean>();

  useEffect(() => {
    if (onChange) {
      onChange(items);
    }
  }, [items, onChange]);

  const onDelete = (index: number) => {
    setItems(prevItems => prevItems.filter((_, i) => i !== index));
  };

  const onSave = (newItem: C) => {
    setItems(prevItems => [...prevItems, newItem]);
    setPopoverVisible(false);
  };

  const onEdit = (newItem: C, index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1, newItem);
    setItems(newItems);
  };

  return (
    <div>
      <ControlHeader {...props} />
      <Style>
        {items.map((item, index) => (
          <StyledOptionControlContainer key={index}>
            <CloseButton onClick={() => onDelete(index)}>
              <Icons.XSmall iconColor={theme.colors.grayscale.light1} />
            </CloseButton>
            <ControlPopover
              title={t('Edit item')}
              content={() =>
                popoverContentRenderer(item, (newItem: C) =>
                  onEdit(newItem, index),
                )
              }
              destroyTooltipOnHide
              trigger={['click']}
            >
              <OptionControlContainer withCaret className="collection-item">
                {itemRenderer(item)}
                <CaretContainer>
                  <Icons.CaretRight iconColor={theme.colors.grayscale.light1} />
                </CaretContainer>
              </OptionControlContainer>
            </ControlPopover>
          </StyledOptionControlContainer>
        ))}
        <ControlPopover
          title={t('Add new item')}
          content={() => popoverContentRenderer(null, onSave)}
          visible={popoverVisible}
          onVisibleChange={setPopoverVisible}
          destroyTooltipOnHide
          trigger={['click']}
        >
          <AddControlLabel>
            <Icons.PlusSmall iconColor={theme.colors.grayscale.light1} />
            {t('Add new item')}
          </AddControlLabel>
        </ControlPopover>
      </Style>
    </div>
  );
}
